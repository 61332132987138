import React, {useEffect} from "react"
import Header from "../../components/header"
import Trade from "../../components/trade"
import { FaTrophy } from 'react-icons/fa'
import teamsData from "../../../static/data/teams.json"
import tradesData from "../../../static/data/trades.json"
import { Chart } from 'chart.js'
import {Helmet} from "react-helmet"

function Page({teams}) {
  const season = "2022";
  const teamNames = teamsData.map((team) => team.seasons[season].name);
  const teamColors = teamsData.map((team) => team.color);
  const trades = tradesData[0][season].trades;

  useEffect(() => {
    const teams = teamsData;    
    
    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].pointsFor),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsPossible),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPercPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => (
            (team.seasons[season].pointsFor / team.seasons[season].pointsPossible) * 100).toFixed(2)
          ),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsAgainst),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].avgScore),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartTrades"), {
        type: 'horizontalBar',
        data: {
          datasets: [{
              data: teams.map((team) => team.seasons[season].tradeCount),
              backgroundColor: teamColors,
          }],
  
          labels: teamNames
        },
        options: {
          legend: {
            display: false
          }
        }
      });

    // doesn't pull from array
    new Chart(document.getElementById("chartHighestScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: [
            '180.6',
            '170.76',
            '164.26',
            '163.58',
            '159.4',
            '158.88',
            '156.46',
            '156.12',
            '155.08',
            '153.7'
          ],
          backgroundColor: [
            teamColors[7],
            teamColors[7],
            teamColors[7],
            teamColors[3],
            teamColors[3],
            teamColors[6],
            teamColors[7],
            teamColors[5],
            teamColors[0],
            teamColors[7]
          ],
        }],
        labels: [
          teamNames[7] + ' - Week 8',
          teamNames[7] + ' - Week 4',
          teamNames[7] + ' - Week 11',
          teamNames[3] + ' - Week 2',
          teamNames[3] + ' - Week 5',
          teamNames[6] + ' - Week 2',
          teamNames[7] + ' - Week 5',
          teamNames[5] + ' - Week 8',
          teamNames[0] + ' - Week 8',
          teamNames[7] + ' - Week 3'
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].aboveAvgPerc),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartWeeklyPlaces"), {
      type: 'line',
      data: {
        labels: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Week 4',
          'Week 5',
          'Week 6',
          'Week 7',
          'Week 8',
          'Week 9',
          'Week 10',
          'Week 11',
          'Week 12',
          'Week 13',
          'Week 14',
          'Week 15',
          'Week 16',
          'Week 17'
        ],
        datasets: 
          teams.map((team,idx) => (
            {
              label: team.seasons[season].name,
              data: team.seasons[season].weeklyPlaces,
              fill: false,
              borderColor: team.color,
              backgroundColor: team.color,
              borderCapStyle: 'square'
            }
          ))
      },
      options: {
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              reverse: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartOriginalTeam"), {
      type: 'polarArea',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].originalTeamPerc),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartFaabSpent"), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].faabSpent),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartTier1"),{
      type:"horizontalBar",
      data:{
        labels: teamNames,
        datasets:[
          {
            label: "QB",
            data: teams.map((team) => team.seasons[season].tier1_QB.length),
            backgroundColor: "#C62828"
          },
          {
            label: "RB",
            data: teams.map((team) => team.seasons[season].tier1_RB.length),
            backgroundColor: "#2E7D32"
          },
          {
            label: "WR",
            data: teams.map((team) => team.seasons[season].tier1_WR.length),
            backgroundColor: "#1565C0"
          },
          {
            label: "TE",
            data: teams.map((team) => team.seasons[season].tier1_TE.length),
            backgroundColor: "#F9A825"
          },
          {
            label: "D/ST",
            data: teams.map((team) => team.seasons[season].tier1_DST.length),
            backgroundColor: "#6A1B9A"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    });
  }, [teams]) 

  return (
    <React.Fragment>
      <Helmet>
        <title>{season} Season Recap | Chode Dynasty</title>
      </Helmet>
      
      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>{season} Season Recap</h1>
            
            <div className="cols-2">
              <div>
                <h2>Final Standings</h2>
                <ol className="standings">
                  <li>
                    <span className="label">1<sup>st</sup></span> Take a poo leave a poo (15-0)
                    <div className="trophy place-1"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">2<sup>nd</sup></span> Charm City Chubb Club (10-5)
                    <div className="trophy place-2"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">3<sup>rd</sup></span> Mahomebois (9-6)
                    <div className="trophy place-3"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">4<sup>th</sup></span> Human Poop (9-6)
                  </li>
                  
                  <li>
                    <span className="label">5<sup>th</sup></span> Chodemissioner (8-7)
                  </li>
                  
                  <li>
                    <span className="label">6<sup>th</sup></span> Tua Tuggin'onthelow'a (7-8)
                  </li>
                  
                  <li>
                    <span className="label">7<sup>th</sup></span> U'z O Mah House (6-9)
                  </li>
                  
                  <li>
                    <span className="label">8<sup>th</sup></span> Jiss Pugs (6-9)
                  </li>
                  
                  <li>
                    <span className="label">9<sup>th</sup></span> frankakatank (3-12)
                  </li>
                  
                  <li>
                    <span className="label">10<sup>th</sup></span> jhoffman (2-13)
                  </li>
                </ol>
              </div>

              <div>
                <h2>Champion Roster</h2>
                <table className="styled champ">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Player</th>
                      <th>Points</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>QB</td>
                      <td>J. Herbert (LAC)</td>
                      <td>20.68</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>S. Barkley (NYG)</td>
                      <td>6.30</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>E. Elliott (DAL)</td>
                      <td>9.70</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>A. St. Brown (DET)</td>
                      <td>8.20</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>T. Higgins (CIN)</td>
                      <td>1.80</td>
                    </tr>
                    <tr>
                      <td>TE</td>
                      <td>T. Kelce (KC)</td>
                      <td>7.80</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>A. Ekeler (LAC)</td>
                      <td>30.10</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>M. Sanders (PHI)</td>
                      <td>6.10</td>
                    </tr>
                    <tr>
                      <td>DST</td>
                      <td>Patriots</td>
                      <td>12.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>102.68</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>

        <div id="awards" className="row">
          <div className="inner">
            <h2>Awards</h2>
            <div className="cols-2">
              <div>
                <h3><span role="img" aria-label="rocket">🚀</span> Highest Scorer</h3>
                <h4>Take a poo leave a poo</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="pile of poo">💩</span>Lowest Scorer</h3>
                <h4>jhoffman</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="four-leaf clover">🍀</span>Best Luck</h3>
                <div className="desc">(least points against)</div>
                <h4>Mahomebois</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="face with head-bandage">🤕</span>Worst Luck</h3>
                <div className="desc">(most points against)</div>
                <h4>jhoffman</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="fire">🔥</span>Best Manager</h3>
                <div className="desc">(highest percentage of possible points scored)</div>
                <h4>Human Poop</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="thinking face">🤔</span>Worst Manager</h3>
                <div className="desc">(lowest percentage of possible points scored)</div>
                <h4>Mahomebois</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="increasing chart">📈</span>Most Improved</h3>
                <div className="desc">(biggest increase from last season in points for)</div>
                <h4>Mahomebois (+69.76)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="decreasing chart">📉</span>Least Improved </h3>
                <div className="desc">(biggest decrease from last season in points for)</div>
                <h4>Chodemissioner (-414.75)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="check mark">✔️</span>Most Consistent Scorer</h3>
                <h4>U'z O Mah House</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="cross mark">❌</span>Least Consistent Scorer </h3>
                <h4>Mahomebois</h4>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="inner">
            <h2>Total Points For</h2>
            <canvas id="chartPF"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Possible</h2>
            <canvas id="chartPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Possible Points Scored</h2>
            <canvas id="chartPercPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Against</h2>
            <canvas id="chartPA"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <div className="chart-container">
              <canvas id="chartAboveAvgPerc"></canvas>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Highest Scores</h2>
            <canvas id="chartHighestScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Finishes</h2>
            <canvas id="chartWeeklyPlaces"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Percentage of Startup Draft Team</h2>
            <div className="chart-desc">(% of original team remaining)</div>
            <div className="chart-container">
              <canvas id="chartOriginalTeam"></canvas>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>FAAB Spent (out of $200)</h2>
            <div className="chart-container">
              <canvas id="chartFaabSpent"></canvas>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Top Scoring Players by Position</h2>
            
            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Quarterback</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>P. Mahomes</td>
                      <td>493.9</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>J. Allen</td>
                      <td>455.2</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>J. Burrow</td>
                      <td>423</td>
                      <td>Charm City Chubb Club</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>J. Hurts</td>
                      <td>413</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>G. Smith</td>
                      <td>355.9</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>K. Cousins</td>
                      <td>342.6</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Goff</td>
                      <td>334.8</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>T. Lawrence</td>
                      <td>333.6</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>J. Herbert</td>
                      <td>322.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>T. Brady</td>
                      <td>316.7</td>
                      <td>U'z O Mah House</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>J. Fields</td>
                      <td>314</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>D. Jones</td>
                      <td>313</td>
                      <td>U'z O Mah House</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Running Back</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>C. McCaffrey</td>
                      <td>314.9</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>A. Ekeler</td>
                      <td>314.2</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>J. Jacobs</td>
                      <td>298.8</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>D. Henry</td>
                      <td>282.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>N. Chubb</td>
                      <td>266.9</td>
                      <td>Charm City Chubb Club</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>S. Barkley</td>
                      <td>254.5</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>T. Pollard</td>
                      <td>229.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Williams</td>
                      <td>216.9</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>D. Cook</td>
                      <td>214.3</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>A. Jones</td>
                      <td>214.1</td>
                      <td>U'z O Mah House</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>J. Mixon</td>
                      <td>210.7</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>R. Stevenson</td>
                      <td>210.6</td>
                      <td>Charm City Chubb Club</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Wide Receiver</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>J. Jefferson</td>
                      <td>304.7</td>
                      <td>Charm City Chubb Club</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>T. Hill</td>
                      <td>286.7</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>D. Adams</td>
                      <td>284.5</td>
                      <td>U'z O Mah House</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>S. Diggs</td>
                      <td>265.2</td>
                      <td>Charm City Chubb Club</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>A. Brown</td>
                      <td>253.6</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>C. Lamb</td>
                      <td>248.1</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Waddle</td>
                      <td>220.7</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>A. St. Brown</td>
                      <td>214.6</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>A. Cooper</td>
                      <td>207</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>D. Smith</td>
                      <td>206.1</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>C. Kirk</td>
                      <td>198.9</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>J. Chase</td>
                      <td>196.9</td>
                      <td>jhoffman</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Tight End</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>T. Kelce</td>
                      <td>260.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>T. Hockenson</td>
                      <td>171.4</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>G. Kittle</td>
                      <td>169.5</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>M. Andrews</td>
                      <td>153</td>
                      <td>Charm City Chubb Club</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>T. Hill</td>
                      <td>143.3</td>
                      <td>U'z O Mah House</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>E. Engram</td>
                      <td>140.4</td>
                      <td>Charm City Chubb Club</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>C. Kmet</td>
                      <td>121.3</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>P. Freiermuth</td>
                      <td>116.7</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>T. Higbee</td>
                      <td>116</td>
                      <td>U'z O Mah House</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>J. Johnson</td>
                      <td>113.8</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>D. Schultz</td>
                      <td>113.2</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>D. Goedert</td>
                      <td>112.7</td>
                      <td>Jiss Pugs</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Defense / Special Teams</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Patriots</td>
                      <td>183</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Cowboys</td>
                      <td>179</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Eagles</td>
                      <td>172</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>49ers</td>
                      <td>170</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Bills</td>
                      <td>157</td>
                      <td>U'z O Mah House</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Ravens</td>
                      <td>142</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Jets</td>
                      <td>136</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Chiefs</td>
                      <td>127</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Jaguars</td>
                      <td>127</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Commanders</td>
                      <td>125</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Bengals</td>
                      <td>124</td>
                      <td>Free Agent</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Colts</td>
                      <td>123</td>
                      <td>Mahomebois</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Tier 1 Player Management</h2>
            <div className="chart-desc">(how many tier 1 players each team had)</div>
            <canvas id="chartTier1"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Reward Winners</h2>
            <table className="styled weekly-reward">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Title</th>
                  <th>Challenge</th>
                  <th>Winner</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                  <td>Mahomebois - 147.10</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                  <td>Mahomebois - 68.7 drop in score</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                  <td>Take a poo leave a poo - J. Hurts had a 58.7 yard pass</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                  <td>Tua Tuggin'onthelow'a - C. Kupp (14)</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                  <td>Take a poo leave a poo - T. Higgins (0.0 pts)<br />Human Poop - D. Waller (0.0 pts)</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                  <td>Human Poop - 16.16 pts</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Halftime</td>
                  <td>Team with the most total points after 7 weeks</td>
                  <td>Take a poo leave a poo - 971.82 pts</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                  <td>Mahomebois - A. Cooper (19.6 pts)</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>The Purge</td>
                  <td>Subtract last week's score from this weeks, the team with the highest remaining score wins</td>
                  <td>Jiss Pugs - 48.02 pts</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Right On Target</td>
                  <td>Team closest to their projected score (over OR under)</td>
                  <td>frankakatank - 1.89 pts off of projected</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                  <td>U'z O Mah House - 111.34 pts</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                  <td>Human Poop - J. Jacobs (229 yards)</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                  <td>Take a poo leave a poo - 10 TDs</td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Lockdown</td>
                  <td>Team with the least amount of yards given up on defense</td>
                  <td>Take a poo leave a poo - Eagles (304 yards)</td>
                </tr>

                <tr>
                  <td>15</td>
                  <td>Consolation Prize</td>
                  <td>Team with the most points for on the season that didn't make the playoffs</td>
                  <td>Chodemissioner - 1609.7 pts</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="trades" className="row">
          <div className="inner">
            <h2>Trades</h2>

            <h3>Total Trades - {trades.length}</h3>
            <canvas id="chartTrades"></canvas>

            <h3>Trade Details</h3>
            <div id="trades-details">
              {trades.map((data,id)=>{
                return <Trade key={id} data={data} teamNames={teamNames} />
              })}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page;